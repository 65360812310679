<template>
  <div class="extration-bdd-frais">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      @click="showFacturesToAvoir('generateAvoir')"
      title="Générer des avoirs"
    >
      <font-awesome-icon icon="check-double"
    /></b-button>
    <b-modal
      ref="generateAvoir"
      id="generateAvoir"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap"
      @hidden="resetModal('generateAvoir')"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Génerer des factures Avoirs</div>
        <div class="iconClose" @click.prevent="hideModal('generateAvoir')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form class="form-modal-custom-style">
            <div class="mt-4 ml-3">
              <p class="paragraph-style">
                Voulez vous générer des factures avoirs Pour le(s) factures(s)?
              </p>
              <b-form-group
                v-if="checkPermission(permission)"
                label-cols-sm="5"
                label-cols-lg="5"
                content-cols-sm
                content-cols-lg="6"
                label="Date de l'avoir : "
                class=" paragraph-style"
              >
                <date-picker
                  value-type="format"
                  format="YYYY-MM-DD"
                  type="date"
                  :value="date"
                  class="inputDateTemplate"
                  @input="handleDateTimePicker"
                ></date-picker>
              </b-form-group>
              <ul v-for="(item, index) in tableToUse" :key="index">
                <li
                  class="paragraph-style"
                  style="list-style: none; color : red !important"
                >
                  {{ item.num }}
                </li>
              </ul>
            </div>
            <div></div>
            <div v-if="error" class="error-msg mb-2">{{ error }}</div>
            <div class="actionModel">
              <b-button
                type="submit"
                class="button-valide-style mt-1"
                @click.prevent="handleGenerate"
              >
                <span>
                  Générer
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style mt-1 ml-3"
                @click.prevent="hideModal('generateAvoir')"
              >
                <span>
                  Annuler
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      ref="errorModal"
      id="errorModal"
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal('errorModal')"
      modal-class="cutsom-modal-bootstrap  modal-dialog-rapport"
    >
      <div class="hader mb-2">
        <div class="titleSetting text-aligne-center col-10">
          Rapport Création Facture(s) Avoir(s) :
          <b-button
            size="sm"
            variant="light"
            class="button-default-style ml-2 export-facture-auto"
            title="Télecharger  cette contenue"
            @click.prevent="downloadContentAutoFacture()"
          >
            <font-awesome-icon icon="cloud-download-alt"
          /></b-button>
        </div>
        <div class="iconClose" @click.prevent="hideModal('errorModal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <b-row ref="divRefFacture">
        <b-col sm="12" md="12" v-if="created.length">
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Facture(s) ajoutée(s) avec succès ({{ created.length }}):
              </b>
              <b-table-simple
                class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Société</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Numero document corrigé</b-th>
                    <b-th class="newcolor">Montant</b-th>
                    <b-th class="newcolor">Nombre des produits</b-th>
                    <b-th class="newcolor">Objet</b-th>
                    <b-th class="newcolor">Template</b-th>
                  </b-tr>
                  <b-tr v-for="(item, index) in created" v-bind:key="index">
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.numero_document_corrige }} </b-td>
                    <b-td>{{ item.montant_ttc }} € </b-td>
                    <b-td>{{ item.number_of_products }} </b-td>
                    <b-td>{{ item.objet }} </b-td>
                    <b-td>{{ item.template }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
        <b-col sm="12" md="12" v-if="deja_exist.length">
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Facture(s) déjà existant(s) ({{ deja_exist.length }}) :
              </b>
              <b-table-simple
                class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                responsive
              >
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">Société</b-th>
                    <b-th class="newcolor">Client</b-th>
                    <b-th class="newcolor">Numero Facure</b-th>
                    <b-th class="newcolor">Numero document corrigé</b-th>
                    <b-th class="newcolor">Montant</b-th>
                    <b-th class="newcolor">Nombre des produits</b-th>
                    <b-th class="newcolor">Objet</b-th>
                    <b-th class="newcolor">Template</b-th>
                  </b-tr>
                  <b-tr v-for="(item, index) in deja_exist" v-bind:key="index">
                    <b-td>{{ item.vendeur }}</b-td>
                    <b-td>{{ item.client }}</b-td>
                    <b-td>{{ item.num }}</b-td>
                    <b-td>{{ item.numero_document_corrige }} </b-td>
                    <b-td>{{ item.montant_ttc }}€ </b-td>
                    <b-td>{{ item.number_of_products }} </b-td>
                    <b-td>{{ item.objet }} </b-td>
                    <b-td>{{ item.template }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>
          </Card>
        </b-col>
        <b-col sm="12" md="12" v-if="no_seq.length">
          <Card>
            <template v-slot:body>
              <b style="color: black"
                >Société sans séquance ({{ no_seq.length }}):</b
              >
              <ul style="list-style-type: none">
                <li
                  v-for="(item, index) in no_seq"
                  v-bind:key="index"
                  style="color: black"
                >
                  {{ item }}
                </li>
              </ul>
            </template>
          </Card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      error: null,
      created: [],
      no_seq: [],
      deja_exist: [],
      tableToUse: [],
      date: null
    };
  },
  props: {
    functionGenerate: { required: true },
    selected: { required: true },
    famille: { required: true },
    permission: { required: true }
  },
  computed: {
    ...mapGetters(['checkPermission'])
  },
  methods: {
    handleDateTimePicker(data) {
      this.date = data;
    },
    downloadContentAutoFacture() {
      html2pdf(this.$refs.divRefFacture, {
        margin: 0.5,
        filename:
          'Rapport' +
          moment(Date.now()).format('YYYY-MM-DD') +
          this.famille +
          '.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'landscape' }
      });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    showModal(ref) {
      this.$refs[ref].show();
    },
    resetModal(ref) {
      this.loading = false;
      this.error = null;
      if (ref == 'errorModal') {
        this.created = [];
        this.deja_exist = [];
        this.no_seq = [];
        this.checkAll = true;
        this.$emit('RefreshData');
      }
    },
    async handleGenerate() {
      this.error = null;
      this.loading = true;
      var bodyFormData = new FormData();
      bodyFormData.append('date', this.date);
      for (let i = 0; i < this.tableToUse.length; i++) {
        bodyFormData.append('ids[' + [i] + ']', this.tableToUse[i].id);
      }
      const response = await this.functionGenerate(bodyFormData);
      if (response.success) {
        this.loading = false;
        this.hideModal('generateAvoir');
        this.error = null;
        this.loading = false;
        this.created = response.response.created;
        this.no_seq = response.response.no_seq;
        this.deja_exist = response.response.deja_exist;
        if (
          this.created.length == 0 &&
          this.no_seq.length == 0 &&
          this.deja_exist == 0
        ) {
          this.$emit('RefreshData');
        } else {
          this.showModal('errorModal');
        }
      } else {
        this.error = response.errors;
        this.loading = false;
      }
    },
    showFacturesToAvoir(ref) {
      this.tableToUse = [];
      for (let i = 0; i < this.selected.length; i++) {
        for (let j = 0; j < this.selected[i].factures.length; j++) {
          if (
            this.selected[i].factures[j] &&
            this.selected[i].factures[j].check == true &&
            this.selected[i].factures[j].avoir_id == null &&
            (this.selected[i].factures[j].type == 'facture' ||
              this.selected[i].factures[j].type == 'Facture')
          ) {
            this.tableToUse.push(this.selected[i].factures[j]);
          }
        }
      }
      let now = new Date();
      this.date = moment(now).format('YYYY-MM-DD');
      this.showModal(ref);
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue')
  }
};
</script>
<style lang="scss">
.paragraph-style {
  color: #343a40 !important;
  font-family: sans-serif;
  font-size: 15px;
}
</style>

<style scoped lang="scss">
.button-export-style {
  background-color: #e4261b;
  &:hover,
  &:focus {
    background-color: #e4261b;
  }
}
</style>
<style lang="scss">
.ModalScrol::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.ModalScrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.ModalScrol::-webkit-scrollbar-thumb {
  background: #b5b5e6;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
</style>
